<template>
  <div class="main">
    <div class="modal">
      <div class="modal-header">
        <div class="heading">
          <div class="large-heading">
            <p>25 000 <span>RUB</span></p>
          </div>
          <div class="smal-heading">
            <p>+250 FS НА ПЕРВЫЙ ДЕПОЗИТ</p>
          </div>
        </div>
        <div class="block-close">
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 16 16"
            fill="#cfd4d8"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.4697 12.5303C11.7626 12.8232 12.2374 12.8232 12.5303 12.5303C12.8232 12.2374 12.8232 11.7626 12.5303 11.4697L9.06066 8L12.5303 4.53033C12.8232 4.23744 12.8232 3.76256 12.5303 3.46967C12.2374 3.17678 11.7626 3.17678 11.4697 3.46967L8 6.93934L4.53033 3.46967C4.23744 3.17678 3.76256 3.17678 3.46967 3.46967C3.17678 3.76256 3.17678 4.23744 3.46967 4.53033L6.93934 8L3.46967 11.4697C3.17678 11.7626 3.17678 12.2374 3.46967 12.5303C3.76256 12.8232 4.23744 12.8232 4.53033 12.5303L8 9.06066L11.4697 12.5303Z"
              fill="#cfd4d8"
            ></path>
          </svg>
        </div>
      </div>
      <div class="registration">
        <div class="registration-form modal-right">
          <h1 class="title">Регистрация</h1>
          <form
            action="#"
            id="registrationForm"
            :class="{'form_disabled': isRequestSending}"
            @submit.prevent="submitForm"
          >
            <div class="form-row">
              <div class="form-group">
                <input type="text" placeholder="Имя Фамилия" id="name" />
                <div class="iconBlock">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 40 40"
                    class="Icon_icon__w6Pjo"
                    preserveAspectRatio=""
                  >
                    <g>
                      <g>
                        <path
                          d="M20 40C8.954 40 0 31.046 0 20 0 8.955 8.954 0 20 0s20 8.955 20 20c0 11.046-8.954 20-20 20zm9.588-7.213A11.979 11.979 0 0 0 20 28c-3.92 0-7.391 1.887-9.581 4.792a15.907 15.907 0 0 0 19.169-.005zM26 18a6 6 0 1 0-6.002 6h.004A6 6 0 0 0 26 18zM20 4C11.164 4 4 11.164 4 20c0 3.788 1.323 7.263 3.523 10.003a16.013 16.013 0 0 1 5.87-4.548C11.328 23.623 10 20.978 10 18c0-5.523 4.477-10 10-10s10 4.477 10 10c0 2.98-1.329 5.625-3.396 7.457a15.993 15.993 0 0 1 5.856 4.568A15.92 15.92 0 0 0 36 20c0-8.836-7.163-16-16-16z"
                        ></path>
                      </g>
                    </g>
                  </svg>
                </div>
              </div>
              <div class="form-group">
                <input type="date" placeholder="Дата рождения" id="date" />
                <div
                  class="iconBlock"
                  onclick="document.getElementById('date').showPicker()"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 14 14"
                    class="DatepickerInput_icon__8IPAc"
                  >
                    <path
                      d="M0 2h3V1a1 1 0 0 1 2 0v1h4V1a1 1 0 0 1 2 0v1h3v12H0zm2 10h10V5H2zm1-6h2v2H3zm3 0h2v2H6zm3 0h2v2H9zM3 9h2v2H3zm3 0h2v2H6z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div class="phone-row">
              <select name="telCode" id="telCode" v-model="telCode">
                <option value="+7" selected>+7</option>
                <option value="+380">+380</option>
              </select>
              <input
                style="width: 100%"
                type="tel"
                id="phone"
                placeholder="Номер телефона"
                required
                v-model="phone"
              />
              <div class="iconBlock phoneIconCountry">
                <img v-if="telCode === '+7'" src="@/assets/img/ru.png" alt="ru" />
                <img v-else src="@/assets/img/ua.png" alt="us" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <input type="password" placeholder="Пароль" id="password" v-model="password" />
              </div>
            </div>
            <div class="form-row">
              <div class="form-group">
                <select id="country" v-model="country">
                  <option value="RU" selected>Россия</option>
                  <option value="UA">Украина</option>
                </select>
                <div class="iconBlock iconCountry">
                  <img :src="require(`@/assets/img/${country.toLowerCase()}.png`)" alt="ru" />
                </div>
              </div>
              <div class="form-group">
                <select id="currency" v-model="currency">
                  <option value="RUB" selected>RUB</option>
                  <option value="UAH">UAH</option>
                </select>
                <div class="iconBlock iconCurrency">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 16"
                    class="Icon_icon__w6Pjo"
                    preserveAspectRatio=""
                  >
                    <path
                      d="M0 0h20v16H0zm2 14h3a3 3 0 00-3-3zm16 0v-3a3 3 0 00-3 3zm0-7a5 5 0 01-5-5H7a5 5 0 01-5 5v2a5 5 0 015 5h6a5 5 0 015-5zm0-5h-3a3 3 0 003 3zM2 2v3a3 3 0 003-3zm8 4a2 2 0 110 4 2 2 0 010-4z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div class="form-check">
              <label class="perm-check" style="margin-top: 0">
                <input type="checkbox" checked required />
                <p>
                  Мне 18 лет или более, я играю для себя и на свои собственные
                  деньги
                </p>
              </label>
              <label class="perm-check">
                <input type="checkbox" checked required />
                <p>
                  Это моя первая и единственная регистрация на Mostbet.com
                </p>
              </label>
              <label class="perm-check">
                <input type="checkbox" checked required />
                <p>
                  У меня нет истории самоисключений ни на одном букмекерском
                  сайте за последние 12 месяцев
                </p>
              </label>
              <label class="perm-check">
                <input type="checkbox" checked required />
                <p>Я прочитал и принимаю Правила</p>
              </label>
              <label class="perm-check">
                <input type="checkbox" checked required />
                <p>
                  Я заявляю, что вся информация, предоставленная мной для
                  Mostbet.com является корректной и может быть проверена, и я
                  понимаю, что предоставление ложной информации в любом виде
                  приведет к аннулированию всех выигрышей от моих игр на
                  Mostbet.com
                </p>
              </label>
              <label class="perm-check">
                <input type="checkbox" checked required />
                <p>
                  Я понимаю и признаю, что Mostbet.com устанавливает
                  определенные ограничения на максимальное количество призов,
                  которые можно выиграть и/или вывести за день/неделю/месяц
                </p>
              </label>
              <label class="perm-check">
                <input type="checkbox" checked required />
                <p>Я принимаю все</p>
              </label>
            </div>
            <div class="form-group" style="width: 100%; margin-top: 14px">
              <input
                type="text"
                id="promo"
                placeholder="Введите пароль"
                value="OPA"
              />
              <div class="iconBlock">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  class="Icon_icon__w6Pjo"
                  preserveAspectRatio=""
                >
                  <path
                    d="M3 2h2.515L7.293.222a1 1 0 011.414 0L10.485 2H13a1 1 0 011 1v2.515l1.778 1.778a1 1 0 010 1.414L14 10.485V13a1 1 0 01-1 1h-2.515l-1.778 1.778a1 1 0 01-1.414 0L5.515 14H3a1 1 0 01-1-1v-2.515L.222 8.707a1 1 0 010-1.414L2 5.515V3a1 1 0 011-1zm-.657 6L4 9.657V12h2.343L8 13.657 9.657 12H12V9.657L13.657 8 12 6.343V4H9.657L8 2.343 6.343 4H4v2.343zM8 5a3 3 0 110 6 3 3 0 010-6zM7 8a1 1 0 102 0 1 1 0 00-2 0z"
                  ></path>
                </svg>
              </div>
            </div>
            <button type="submit" class="submit-btn">НАЧАТЬ ИГРУ!</button>
          </form>
        </div>
      </div>
      <div class="iconAbsolut-1 iconAbsolut"></div>
      <div class="iconAbsolut-2 iconAbsolut"></div>
      <div class="iconAbsolut-3 iconAbsolut"></div>
      <div class="iconAbsolut-4 iconAbsolut"></div>
      <div class="iconAbsolut-5 iconAbsolut"></div>
      <div class="iconAbsolut-6 iconAbsolut"></div>
      <div class="iconAbsolut-7 iconAbsolut"></div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification'
import axios from 'axios'

export default {
  name: 'landing-page',
  data () {
    return {
      telCode: '+7',
      phone: '',
      password: '',
      country: 'RU',
      currency: 'RUB',
      promocode: 'SKUF',
      checkbox: false,
      isRequestSending: false
    }
  },
  methods: {
    submitForm () {
      if (this.isRequestSending) {
        return
      }

      this.isRequestSending = true

      const data = {
        user: {
          country: this.country,
          currency: this.currency,

          bonus_code: this.promocode,
          captcha: '',
          parent_refcode: '',
          profile_attributes: {
            age_acceptance: true,
            terms_acceptance: true,
            game_currency: this.currency,
            receive_newsletters: false,
            receive_promos: false,
            last_name: '',
            first_name: '',
            receive_sms_promos: false,
            nickname: '',
            currency: this.currency,
            language: 'ru',
            country: this.country
          },
          phone: {
            country: this.country,
            number: `${this.telCode}${this.phone}`
          },
          password: this.password,
          password_confirmation: this.password
        },
        lang: 'ru'
      }

      console.log(data)

      this.fetchData(data)
    },
    fetchData (data) {
      axios.post('/script.php', data, {
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
        .then((response) => {
          this.isRequestSending = false

          if (response.status === 200 || response.statusText === 'OK') {
            if (response.data) {
              const { token, url } = response.data

              if (token && token.length) {
                useToast().success('Успешная регистрация! Перенаправление...')
                document.location.href = url

                return
              }
            }

            useToast().error('Ошибка при регистрации')

            return
          }

          console.log('error', response)
          useToast().error('Ошибка при отправке запроса')
        })
        .catch((error) => {
          this.isRequestSending = false
          console.log('request error', error)
          useToast().error('Ошибка при отправке запроса')
        })
    }
  }
}
</script>

<style src="./style.scss" lang="scss"></style>
